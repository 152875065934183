.project-card{
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0px;
    padding: 8px 0px;
}
.project-photo{
    width: 40%;
}

.project-info{
    margin-right: 24px;
}

.project-title{
    font-size: 20px;
    font-weight: 600;
}

.project-tags{
    display: flex;
    max-width: 60%;
    flex-wrap: wrap;
}

.tag {
    padding:  4px 8px;
    background-color: blue;
    border-radius: 4px;
    margin-right: 6px;
    margin-bottom: 4px;
}

.project-links a {
    text-decoration: none;
    color: black;
}

.project-links{
    margin-top: 6px;
    display: flex;
}

.link-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: blue;
    width: max-content;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 16px;
    margin-right: 6px;
}

.link-button i {
    display: flex;
    align-items: center;
    mask-repeat: 4px;
}

@media only screen and (max-width: 720px) {
    .project-photo {
        width: 70%;
    }
    .project-card {
        flex-direction: column-reverse;
    }
    .project-info {
        margin-left: 0px;
        margin-top: 12px;
    }
    .project-tags {
        max-width: 100%;
    }
}

.button {
    cursor: pointer;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    color: white;
    border:2px solid transparent;
    border-radius:10px;
    border-right-color:#743ad5;
    border-left-color:#d53a9d;
    background:
      linear-gradient(to left,
        rgb(116, 58, 213) 0%, rgb(186, 58, 143) 70% ,
        transparent 70%,  transparent 85%, 
        rgb(201, 58, 128) 85%, rgb(213, 58, 157) 100%) top/100% 2px,
      linear-gradient(to left, #743ad5 0%, #d53a9d 100%) bottom/100% 2px;
    background-repeat:no-repeat;
    transition: background-color 2s ease-out 100ms
  }

  .button:hover {
    background-repeat: repeat;
  }

.card {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    transition: 0.3s;
    padding: 15px;
    border-radius: 5px
}